import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import i18n from 'i18next';
import { I18nextProvider } from 'react-i18next';
const root = createRoot(document.getElementById('root'));
i18n.init({
  lng: 'fr',
  debug: true,
  resources: {
    en: {
      translation: {
        "Welcome": "Welcome",
        "Sign in to continue": "Sign in to continue",
        "Forgot Password": "Forgot password",
        "Password": "Password",
        "Enter your email or username": "Enter your email or username",
        "Login": "Login",
        "Please enter your email and password": "Please enter your email and password",
        "Enter the email address you used when joining, and we’ll send reset instructions to reset your password.": "Enter the email address you used when joining, and we’ll send reset instructions to reset your password.",
        "Email": "Email",
        "Submit": "Submit",
        "Reset Password": "Reset Password",
        "New Password": "New Password",
        "Confirm Password": "Confirm Password",
        "Save": "Save",
        "Please input your new password": "Please input your new password",
        "Please confirm your password": "Please confirm your password",
        "The new password and confirm password do not match!": "The new password and confirm password do not match!",
        "Invalid or expired token": "Invalid or expired token",
        "Please check your email for the latest reset link or request a new one.": "Please check your email for the latest reset link or request a new one.",
        "Company Name": "Company Name",
        "Action": "Action",
        "No users found": "No users found",
        "View": "View",
        "Delete": "Delete",
        "Edit": "Edit",
        "User Name": "User Name",
        "Role": "Role",
        "View Profile": "View Profile",
        "Purchase New Card": "Purchase New Card",
        "Add Company": "Add Company",
        "Confirm": "Confirm",
        "Are you sure you want to delete this user?": "Are you sure you want to delete this user?",
        "User Deleted Successfully": "User Deleted Successfully",
        "Are you sure you want to delete this Company?": "Are you sure you want to delete this Company?",
        "Company Updated Successfully": "Company Updated Successfully",
        "Failed: Something went wrong with the server.": "Failed: Something went wrong with the server.",
        "Failed: No response received from the server.": "Failed: No response received from the server.",
        "Failed: Error setting up the request.": "Failed: Error setting up the request.",
        "Data exchanged successfully": "Data exchanged successfully",
        "Purchase New Card": "Purchase New Card",
        "No Company found": "No Company found",
        "No users found": "No users found",
        "Update Company": "Update Company",
        "Cancel": "Cancel",
        "OK": "OK",
        "Update": "Update",
        "Company Name": "Company Name",
        "Please enter a phone number": "Please enter a phone number",
        "Invalid phone number format": "Invalid phone number format",
        "Please enter an email": "Please enter an email",
        "Please input a valid email!": "Please input a valid email!",
        "Company Deleted Successfully": "Company Deleted Successfully",
        "Name": "Name",
        "Owner Name": "Owner Name",
        "Date": "Date",
        "First Name": "First Name",
        "Last Name": "Last Name",
        "Please input your first name!": "Please input your first name!",
        "first name is required": "first name is required",
        "Please input your last name!": "Please input your last name!",
        "Please input your company name!": "Please input your company name!",
        "Please input your password!": "Please input your password!",
        "Phone": "Phone",
        "Companies": "Companies",
        "Logout": "Logout",
        "Leads": "Leads",
        "Settings": "Settings",
        "Users": "Users",
        "Update User": "Update User",
        "Profile Picture": "Profile Picture",
        "Cover Picture": "Cover Picture",
        "Invalid URL format": "Invalid URL format",
        "Zip Code": "Zip Code",
        "Postal Address": "Postal Address",
        "Country": "Country",
        "City": "City",
        "Facebook": "Facebook",
        "Instagram": "Instagram",
        "Linkedin": "Linkedin",
        "Social Link type": "Social Link type",
        "Additional Social link": "Additional Social link",
        "Additional Email": "Additional Email",
        "Another Phone": "Another Phone",
        "Biography": "Biography",
        "No media available, please add one.": "No media available, please add one.",
        "Download QR Code": "Download QR Code",
        "Show QRCode to share your profile": "Show QRCode to share your profile",
        "Save Contact": "Save Contact",
        "Exchange": "Exchange",
        "The User is no longer available": "The User is no longer available",
        "Confirm Password": "Confirm Password",
        "No Leads found": "No Leads found",
        "User Update Successfully": "User Update Successfully",
        "Add User": "Add User",
        "Add": "Add",
        "Job title": "Job Title",
        "Poste": "Poste",
        "Personal": "Personal",
        "Professionnel": "Professional",
        "Phone Type": "Phone Type",
        "Failed: Error setting up the request.": "Failed: Error setting up the request.",
        "Failed: Something went wrong with the server.": "Failed: Something went wrong with the server.",
        "Failed: No response received from the server.": "Failed: No response received from the server.",
        "Are you the owner of the file? Click here to login?": "Are you the owner of the file? Click here to login?",
        "Social Link": "Social Link",
        "Add Another Social Link": "Add Another Social Link",
        "Enter your social media link": "Enter your social media link",
        "Twitter Tiktok": "Twitter Tiktok",
        "No media available, please add one.": "No media available, please add one.",
        "Download QR Code": "Download QR Code",
        "Show QRCode to share your profile": "Show QRCode to share your profile",
        "The User is no longer available": "The User is no longer available",
        "Settings updated successfully!": "Settings updated successfully!",
        "Failed to update user settings": "Failed to update user settings",
        "Profile Setting": "Profile Setting",
        "Company name is required!": "Company name is required!",
        "error saving image": "error saving image",
        "Company": "Company",
        "French": "French",
        "English": "English",
        "Image Update successfully": "Image Update successfully",
        "Edit Cover Photo": "Edit Cover Photo",
        "Zoom": "Zoom",
        "User Added Successfully": "User Added Successfully",
        "Company added successfully": "Company added successfully",
        "Password must be required": "Password must be required",
        "Password must be at least eight characters long and include both letters and numbers.": "Password must be at least eight characters long and include both letters and numbers.",
        "Company added successfully. Please check your email, including the Spam folder..": "Company added successfully. Please check your email, including the Spam folder.",
        "Site internet": "Site internet",
        "Site internet type": "Site internet type",
        "Enter your site internet url": "Enter your site internet url",
        "Additional Site internet": "Additional site internet",
        "No media available please add one": "No media available please add one",
        "Download QR Code": "Download QR Code",
        "QR Code": "QR Code",
        "Back now?": "Back now?",
        "read more": "read more",
        "show less": "show less",
        "Zoom In": "Zoom In",
        "Zoom Out": "Zoom Out",
        "Upload New": "Upload New",
      }
    },
    fr: {
      translation: {
        "Welcome": "Bienvenue",
        "Sign in to continue": "Connectez-vous pour continuer",
        "Forgot Password": "Mot de passe oublié / Première connexion",
        "Password": "mot de passe",
        "Enter your email or username": "Entrez votre email ou votre nom d'utilisateur",
        "Login": "Se connecter",
        "Please enter your email and password": "Veuillez entrer votre email et votre mot de passe",
        "Enter the email address you used when joining, and we’ll send reset instructions to reset your password.": "Entrez l'adresse e-mail que vous avez utilisée lors de votre inscription et nous vous enverrons des instructions de réinitialisation pour réinitialiser votre mot de passe.",
        "Email": "E-Mail",
        "Submit": "Soumettre",
        "Reset Password": "réinitialiser le mot de passe",
        "New Password": "nouveau mot de passe",
        "Confirm Password": "Confirmez le mot de passe",
        "Save": "Sauvegarder",
        "Please input your new password": "Veuillez saisir votre nouveau mot de passe",
        "Please confirm your password": "Veuillez confirmer votre mot de passe",
        "The new password and confirm password do not match!": "Le nouveau mot de passe et le mot de passe de confirmation ne correspondent pas !",
        "Invalid or expired token": "Jeton invalide ou expiré",
        "Please check your email for the latest reset link or request a new one.": "Veuillez vérifier votre courrier électronique pour connaître le dernier lien de réinitialisation ou en demander un nouveau.",
        "Company Name": "Nom de l'entreprise",
        "Add Another Social Link": "Ajouter un autre lien social",
        "Enter your social media link": "Entrez votre lien vers les réseaux sociaux",
        "Twitter Tiktok": "Twitter Tiktok",
        "Image Update successfully": "Mise à jour de l'image réussie",
        "Action": "Action",
        "No users found": "Aucun utilisateur trouvé",
        "View": "Vue",
        "Delete": "Supprimer",
        "Edit": "Modifier",
        "User Name": "Nom d'utilisateur",
        "Role": "Rôle",
        "Job title": "Poste",
        "View Profile": "Voir le profil",
        "Purchase New Card": "Acheter une nouvelle carte",
        "Add Company": "Ajouter une entreprise",
        "Confirm": "Confirmer",
        "Are you sure you want to delete this user?": "Êtes-vous sûr de vouloir supprimer cet utilisateur ?",
        "User Deleted Successfully": "Utilisateur supprimé avec succès",
        "Are you sure you want to delete this Company?": "Êtes-vous sûr de vouloir supprimer cette entreprise ?",
        "Company Updated Successfully": "Entreprise mise à jour avec succès",
        "Failed: Something went wrong with the server.": "Échec : Un problème est survenu avec le serveur.",
        "Failed: No response received from the server.": "Échec : Aucune réponse reçue du serveur.",
        "Failed: Error setting up the request.": "Échec : Erreur lors de la configuration de la requête.",
        "Purchase New Card": "Acheter une nouvelle carte",
        "No Company found": "Aucune entreprise trouvée",
        "No users found": "Aucun utilisateur trouvé",
        "Update Company": "Mettre à jour l'entreprise",
        "Cancel": "Annuler",
        "OK": "D'ACCORD",
        "Update": "Mettre à jour",
        "Company Name": "Nom de l'entreprise",
        "Please enter a phone number": "Veuillez saisir un numéro de téléphone",
        "Invalid phone number format": "Format de numéro de téléphone invalide",
        "Please input a valid email!": "Veuillez saisir une adresse e-mail valide !",
        "Please enter an email": "Veuillez saisir une adresse e-mail",
        "Company Deleted Successfully": "Société supprimée avec succès",
        "User Added Successfully": "Utilisateur ajouté avec succès",
        "Name": "Nom",
        "Owner Name": "Propriétaire de la carte",
        "No Leads found": "Vous n'avez pas encore de prospects",
        "First Name": "Prénom",
        "Last Name": "Nom de famille",
        "Please input your first name!": "Veuillez entrer votre prénom !",
        "first name is required": "Le prénom est requis",
        "last name is required": "le nom de famille est obligatoire",
        "Please input your last name!": "Veuillez entrer votre nom de famille !",
        "Please input your company name!": "Veuillez entrer le nom de votre entreprise !",
        "Please input your password!": "Veuillez entrer votre mot de passe !",
        "Company name is required!": "Le nom de l'entreprise est requis !",
        "Data exchanged successfully": "Données échangées avec succès",
        "Phone": "Téléphone",
        "Companies": "Entreprises",
        "Logout": "Se déconnecter",
        "Leads": "Prospects",
        "Settings": "Paramètres",
        "Users": "Utilisateurs",
        "Update User": "Mettre à jour l'utilisateur",
        "Profile Picture": "Photo de profil",
        "Cover Picture": "Photo de couverture",
        "Invalid URL format": "Format d'URL invalide",
        "Poste": "Poste",
        "Zip Code": "Code postal",
        "Postal Address": "Adresse postale",
        "Country": "Pays",
        "City": "Ville",
        "Facebook": "Lien Facebook",
        "Instagram": "Lien Instagram",
        "Linkedin": "Lien Linkedin",
        "Social Link": "Lien social",
        "Social Link type": "Type de lien social",
        "Additional Social link": "Lien social supplémentaire",
        "Additional Email": "E-mail supplémentaire",
        "Another Phone": "Autre téléphone",
        "Are you the owner of the file? Click here to login?": "Vous êtes le propriétaire de la fiche ? Cliquez ici pour vous connecter?",
        "Biography": "Biographie",
        "No media available, please add one.": "Aucun média disponible, veuillez en ajouter un.",
        "Download QR Code": "Télécharger le code QR",
        "Show QRCode to share your profile": "Afficher le code QR pour partager votre profil",
        "Save Contact": "Enregistrer contact",
        "Exchange": "Échange",
        "The User is no longer available": "L'utilisateur n'est plus disponible",
        // new data
        "Confirm Password": "Confirmer le mot de passe",
        "Add User": "Ajouter un utilisateur",
        "Add": "Ajouter",
        "Personal": "Personnel",
        "Professionnel": "Professionnel",
        "Phone Type": "Type de téléphone",
        "Failed: Error setting up the request.": "Échec : Erreur lors de la configuration de la requête.",
        "Failed: Something went wrong with the server.": "Échec : Quelque chose s'est mal passé avec le serveur.",
        "Failed: No response received from the server.": "Échec : Aucune réponse reçue du serveur.",
        "Social Link": "lien vers vos réseaux sociaux",
        "No media available, please add one.": "Pas de média disponible, veuillez en ajouter un.",
        "Download QR Code": "Télécharger le code QR",
        "Show QRCode to share your profile": "Afficher le code QR pour partager votre profil",
        "The User is no longer available": "L'utilisateur n'est plus disponible",
        "Settings updated successfully!": "Paramètres mis à jour avec succès !",
        "Failed to update user settings": "Échec de la mise à jour des paramètres de l'utilisateur",
        "Profile Setting": "Paramètres du profil",
        "User Update Successfully": "Mise à jour utilisateur réussie",
        "error saving image": "erreur lors de l'enregistrement de l'image",
        "Edit Cover Photo": "Modifier la photo de couverture",
        "Company added successfully": "Entreprise ajoutée avec succès",
        "Password must be required": "Le mot de passe doit être requis",
        "Company added successfully. Please check your email, including the Spam folder.": "Entreprise ajoutée avec succès. Vérifiez votre courrier électronique, y compris le spam.",
        "Password must be at least eight characters long and include both letters and numbers.": "Le mot de passe doit comporter au moins huit caractères et inclure à la fois des lettres et des chiffres.",
        "Zoom": "Zoom",
        "Company": "Entreprise",
        "Date": "Date",
        "Site internet": "Internet du site",
        "Site internet type": "Nom du lien",
        "Enter your site internet url": "Entrez le lien Internet de votre site",
        "Additional Site internet": "Site Internet supplémentaire",
        "Download QR Code": "Télécharger le code QR",
        "No media available please add one": "aucun média disponible veuillez en ajouter un",
        "Back now?": "En arrière maintenant?",
        "QR Code": "QR Code",
        "Shope Link": "Acheter ma carte SmartConnect",
        "French": "Français",
        "English": "Anglais",
        "read more": "En savoir plus",
        "show less": "montrer moins",
        "Zoom In": "Zoomer",
        "Zoom Out": "Zoom arrière",
        "Upload New": "Télécharger nouveau",
      }
    }
  }
});
root.render(
  <I18nextProvider i18n={i18n}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </I18nextProvider>
);
